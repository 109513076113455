
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/config";
import KTUsers from "@/views/main/userManagement/Users.vue";
import KTRoles from "@/views/main/userManagement/Roles.vue";
import KTPermissons from "@/views/main/userManagement/Permissions.vue";

export default defineComponent({
  name: "Users Managements",
  components: { KTUsers, KTPermissons, KTRoles },
  props: {
    widgetClasses: String,
  },
  setup() {
    const isUserGroup = ref(true);
    const isPermission = ref(false);
    const isRoles = ref(false);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Groups List", ["Groups"]);
    });
    const handlePermission = () => {
      isUserGroup.value = false;
      isPermission.value = true;
      isRoles.value = false;
    };

    const handleUser = () => {
      isUserGroup.value = true;
      isPermission.value = false;
      isRoles.value = false;
    };

    const handleRoles = () => {
      isUserGroup.value = false;
      isPermission.value = false;
      isRoles.value = true;
    };

    return {
      handlePermission,
      handleUser,
      isPermission,
      isUserGroup,
      KTUsers,
      KTPermissons,
      isRoles,
      handleRoles,
      KTRoles,
    };
  },
});
