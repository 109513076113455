
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/config";
import KTAddPermission from "@/views/main/userManagement/modals/AddPermission.vue";

export default defineComponent({
  name: "permissions",
  components: { KTAddPermission },
  props: {
    widgetClasses: String,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Permission", ["UsersManagement"]);
    });

    return { KTAddPermission };
  },
});
