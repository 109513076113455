
import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/config";
import GroupCard from "@/views/main/userManagement/roles/parts/GroupCard.vue";
import UpdateRole from "@/views/main/userManagement/modals/UpdateRole.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { setModal } from "@/core/helpers/config";

export default defineComponent({
  components: { UpdateRole, GroupCard },
  props: {
    widgetClasses: String,
  },
  setup() {
    const store = useStore();
    store.dispatch(Actions.FETCH_GROUPS);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Roles List", ["UsersManagement"]);
    });

    const groups = computed(() => {
      return store.getters.getGroups;
    });

    const getUserAccessToken = computed(() => {
      return store.getters.getUserAccessToken;
    });
    console.log(getUserAccessToken, "getUserAccessToken");

    return {
      UpdateRole,
      GroupCard,
      groups,
      setModal,
    };
  },
});
