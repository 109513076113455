import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { id: "kt_post" }
const _hoisted_2 = { id: "kt_content_container" }
const _hoisted_3 = { class: "row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9" }
const _hoisted_4 = { class: "col-md-4" }
const _hoisted_5 = { class: "card h-md-100" }
const _hoisted_6 = { class: "card-body d-flex flex-center" }
const _hoisted_7 = { class: "fw-bolder fs-3 text-gray-600 text-hover-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GroupCard = _resolveComponent("GroupCard")!
  const _component_UpdateRole = _resolveComponent("UpdateRole")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (item, index) => {
            return (_openBlock(), _createBlock(_component_GroupCard, {
              key: index,
              name: item.name
            }, null, 8, ["name"]))
          }), 128)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("button", {
                  class: "btn btn-clear d-flex flex-column flex-center",
                  onClick: _cache[0] || (_cache[0] = () => _ctx.setModal('AddRole'))
                }, [
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("addNewRole")), 1)
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_UpdateRole)
  ], 64))
}