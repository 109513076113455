
import { defineComponent, onMounted, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/config";
import KTAddUser from "@/components/modals/wizards/AddUser.vue";
import { useStore } from "vuex";
import { setModal } from "@/core/helpers/config";
import { ElNotification } from "element-plus";
import Avatar from "@/components/shared/Avatar.vue";

export default defineComponent({
  name: "users",
  components: { KTAddUser, ElNotification, Avatar },
  props: {
    widgetClasses: String,
  },
  setup() {
    const store = useStore();
    const handleChangeStateModal = () => {
      setModal("KTAddUser");
    };
    const modalState = computed(() => {
      return store.getters.getModalState;
    });

    const isInviteUser = computed(() => {
      return store.getters.getInviteUser;
    });
    onMounted(() => {
      setCurrentPageBreadcrumbs("Users", ["UsersManagement"]);
    });

    return { modalState, handleChangeStateModal, isInviteUser };
  },
});
